<template>
    <div class="add-agricultural">
         <van-form @submit="submitData()" class="form-container">
             <van-field
                v-model="agricultural.massifName"
                label="地块名称"
                class="title-label"
                readonly
                required
            /> 
            <van-field
                v-model="agricultural.massifName"
                placeholder="请输入地块名称"
                class="title-input"
                readonly
                :rules="[{ required: true}]"
                disabled
            /> 
            <van-action-sheet
                v-model="show"
                :actions="allPlot"
                cancel-text="取消"
                close-on-click-action
                @cancel="onCancel"
                @select="selectPlot"
            />
            <van-field
                v-model="agricultural.context"
                label="作业内容"
                class="title-label"
                readonly
                required
            /> 
            <van-field
                v-model="agricultural.context"
                rows="3"
                autosize
                name="context"
                label=""
                type="textarea"
                class="context-input"
                maxlength="50"
                placeholder="请输入内容"
                :rules="[{ required: true}]"
            />  
            <van-field
                v-model="agricultural.inputsName"
                label="投入品"
                class="title-label"
                readonly
                required
            /> 
            <van-field
                v-model="agricultural.inputsName"
                name="inputsName"
                placeholder="请输入投入品"
                class="title-input"
                readonly
                @click="clickInput"
                :rules="[{ required: true}]"
            /> 
            <van-action-sheet
                v-model="showInput"
                :actions="allInput"
                cancel-text="取消"
                close-on-click-action
                @cancel="onCancelInput"
                @select="selectInput"
            />
            <div class="img-label">上传图片({{num}}/5)</div>
            <div class="img-container">
                <van-uploader v-model="fileList" accept="image/*" :max-size="5 * 1024 * 1024" @oversize="onOversize"
                    :before-read="beforeRead" :before-delete="beforeDelete"
                />
            </div>
            <div class="form-bottom">
                <van-button block type="info" native-type="submit" v-if="isAdd">新增作业</van-button>
                <van-button block type="info" native-type="submit" v-else>编辑作业</van-button>
            </div>
         </van-form>
        
    </div>
</template>

<script>
import axios from 'axios';
import {getToken, getMassifId} from '@/utils/cookie';
import { listPageMassif, listPageInputs,addMassifWork,modifyMassifWork} from '@/api/plotManagement';
export default {
    data() {
        return {
            isAdd: true,//新增/编辑
            show: false,//是否显示所有地块
            showInput: false,//是否显示所有投入品
            listPlot: {
                pageIndex: 1,
                pageSize: 500,
                total: 0
            },
            agricultural: {
                massifId: '',
                context: '',
                inputsName: '',
                massifName: '',
                images: '',
                inputsId: ''
            },
            allPlot: [],
            allInput: [],
            num: 0,
            fileList: [],
            allUrl: []
        }
    },
    mounted() {
        this.listPageMassif();
        this.listPageInputs();
    },
    methods: {
        //提交数据
        submitData() {
            this.agricultural.images = this.allUrl.join(',');
            if (this.isAdd) {
                //传入地块id
                this.agricultural.massifId = getMassifId();
                addMassifWork(this.agricultural).then(res => {
                    if (res.code == 200) {
                        this.$notify({type: 'success', message: '新增作业成功'});
                        this.$router.push({path: '/agriculturalRecords'});
                    } 
                })
            } else {
                modifyMassifWork(this.agricultural).then(res => {
                    if (res.code == 200) {
                        this.$notify({type: 'success', message: '编辑作业成功'});
                        this.$router.push({path: '/agriculturalRecords'});
                    } 
                })
            }
            
        },
        /** 获取所有地块 */
        listPageMassif() {
            listPageMassif(this.listPlot).then(res => {
                if (res.code == 200) {
                    this.allPlot = [];
                    res.data.forEach(item => {
                        if (getMassifId() == item.massifId) { // 后台不愿意在农事记录里面返地块名称所以循环遍历
                            this.agricultural.massifName = item.massifName;
                        }
                        if (item) {
                            let obj = {
                                name: item.massifName,
                                parkId: item.parkId,
                                massifId: item.massifId,
                            };
                            this.allPlot.push(obj);
                        }
                    });
                }
            });
        },
        /** 获取所有投入品 */
        listPageInputs() {
            let obj = {
                includeSub: true,
                pageIndex: 1,
                pageSize: 0,
            }
            this.allInput = [];
            listPageInputs(obj).then(res => {
                if (res.code == 200) {
                    this.allInput = res.data || []
                }
            })
        },
        /** 显示选择地块 */
        clickPlot() {
            this.show = true;
        },
        /** 选择地块 */
        selectPlot(val) {
            this.agricultural.massifName = val.name;
            this.agricultural.massifId = val.massifId;
        },
        /** 取消 */
        onCancel() {
            this.show = false;
        },
        clickInput() {
            this.showInput = true;
        },
        selectInput(val) {
            this.agricultural.inputsName = val.name;
            this.agricultural.inputsId = val.inputsId;
        },
        onCancelInput() {
            this.showInput = false;
        },
         /** 文件上传限制 */
        onOversize() {
            this.$notify({ type: 'warning', message: '文件大小不能超过 5M' });
        },
        /** 文件上传前 */
        beforeRead(file) {
            /* if (file.type != 'image/jpeg' && file.type != 'image/png') {
                this.$notify({ type: 'warning', message: '请上传jpg/png格式图片' });
                return false;
            } */
            if (this.allUrl.length >= 5) {
                this.$notify({ type: 'warning', message: '最多上传5张图片' });
                return false;
            }
            let formData = new FormData(); // 为上传文件定义一个formData对象
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': getToken()
                }
            };
            formData.append('file', file)
            axios.post('http://172.26.202.35:8100/nj/file/upload', formData, config).then(res => {
                if (res.data.code == 200) {
                    this.allUrl.push(res.data.data[0]);
                    this.num = this.allUrl.length;
                }
            });
            return true;
        },
        /** 删除图片 */
        beforeDelete(file, obj) {
            this.allUrl.forEach((item, index) => {
                console.log(index,obj.index)
                if (index == obj.index) {
                    this.allUrl.splice(index, 1);
                    this.num = this.allUrl.length;
                    // console.log(this.allUrl)
                    return false;
                }
            });
            return true
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (to.params.context) {
                vm.isAdd = false;
                vm.fileList = [];
                vm.allUrl = [];
                vm.agricultural = to.params;
                console.log(to.params)
                if (to.params.images.indexOf(',') != -1) {
                    let arr = to.params.images.split(',');
                    arr.forEach(item => {
                        if (item) {
                            let obj = {
                                url: item, 
                                isImage: true
                            }
                            vm.fileList.push(obj);
                            vm.allUrl.push(item)
                        }
                    });
                } else {
                    if (to.params.images) {
                        let obj = {
                            url: to.params.images,
                            isImage: true
                        }
                        vm.fileList.push(obj);
                        vm.allUrl.push(to.params.images)
                    }
                }
                // vm.num = vm.fileList.length;
                vm.num = vm.allUrl.length;
                document.title = '编辑记录';
            } else {
                vm.isAdd = true;
                vm.num = 0;
                document.title = '新增记录';
            }
        });
    }
}
</script>

<style lang="scss" scoped>
    .add-agricultural {
        height: 100%;
        background: #fff;
        .form-container {
            padding: 14px;
        }
        .title-input  { 
            height: 50px;
            box-shadow: 0px 0px 8px 0px rgba(205,205,205,0.5);
            border-radius: 4px;
            background: #fff;
        }
        .context-input {
            box-shadow: 0px 0px 8px 0px rgba(205,205,205,0.5);
            border-radius: 4px;
            background: #fff;
        }
        .form-bottom {
            position: fixed;
            left: 12px;
            bottom: 10px;
            width: 346px;
        }
        .img-label {
            margin-top: 14px;
            margin-bottom: 14px;
            font-size: 14px;
            color: #333;
        }
        .img-container {
            margin-top: 8px;
            .van-image {
                margin-right: 4px;
            }
        }
    }
</style>
<style lang="scss">
    .add-agricultural {
        .title-label {
            font-size: 16px;
            font-weight: 500;
            color: #000;
            margin-top: 5px;
            .van-cell__value  {
                display: none;
            }
        }
    }
</style>